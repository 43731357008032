// @file User accounts store
import initialUser from '@@/bits/current_user'
import { captureNonNetworkFetchError } from '@@/bits/error_tracker'
import { currentHostname } from '@@/bits/location'
import { buildUserIdCacheKey, fetchCachedQuery } from '@@/bits/query_client'
import { NATIVE_HOST } from '@@/bits/url'
import { displayNameForUser, UserRole } from '@@/bits/user_model'
import { FetchJsonStatus, LibraryMembershipRole, LibraryType, UserAccountType } from '@@/enums'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { LibraryApi } from '@@/surface/api/library'
import { fetchJson } from '@@/surface/api_fetch'
import type {
  AccountKey,
  AccountMenuItem,
  JsonApiData,
  JsonApiResponse,
  Library,
  LibraryAccess,
  LibraryId,
  LibraryMembership,
  Tenant,
  TenantId,
  User,
  UserId,
} from '@@/types'
import type { JsonAPIResource } from '@padlet/arvo'
import { sortBy } from 'es-toolkit'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const NATIVE_TENANT_ID = 1
export const STARK_INDUSTRIES_LIBRARY_ID = 307299
export const PADLET_COLLEGE_TENANT_ID = 1609
const DEFAULT_AVATAR = 'https://padlet.net/avatars/tenant_avatar.webp'

const EMPTY_LIBRARY_ACCESS: LibraryAccess = {
  canViewLibraryInfo: false,
  canUpdateLibraryInfo: false,
  canSetupLti: false,
  canUpdatePermissions: false,
  canViewLibraryMemberships: false,
  canViewLibraryAnalytics: false,
  canManageMembers: false,
  canManageBilling: false,
  canTrashLibrary: false,
  canUseLibraryAsOwner: false,
  canUseLibraryAsAdmin: false,
  canUseLibraryAsTeacher: false,
  canViewLibrarySecurity: false,
  canInviteSchoolAdmins: false,
  canInviteSchoolTeachers: false,
  canInviteSchoolStudents: false,
  canInviteMembers: false,
  canViewGallery: false,
  canManageContentSafetySettings: false,
  canTransferWalls: false,
}

export const useUserAccountsStore = defineStore('userAccountsStore', () => {
  const globalSnackbarStore = useGlobalSnackbarStore()

  function genericFetchError(payload: { error: any; source: string }): void {
    captureNonNetworkFetchError(payload.error, { source: payload.source })
    globalSnackbarStore.genericFetchError()
  }

  /**
   * ==================== PERSONAL ACCOUNTS ====================
   */

  // user is always expected to have a defined value, `window.ww.vueStartingState.user` should be set to the window object on the server side.
  const user = ref<User>(initialUser)
  const usersById = ref<Record<UserId, User>>({})
  const tenantsById = ref<Record<TenantId, Tenant>>({})
  const isNativeAccount = computed((): boolean => user.value?.tenant_id === NATIVE_TENANT_ID)

  const currentUser = computed((): User => {
    return usersById.value[user.value.id] ?? user.value
  })
  const currentTenant = computed((): Tenant | null => {
    if (Object.keys(tenantsById.value).length === 0) return null
    if (currentUser.value == null) return null
    return tenantsById.value[currentUser.value.tenant_id]
  })
  const currentTenantName = computed((): string => currentTenant.value?.name ?? '')
  const currentTenantLogo = computed((): string =>
    currentTenant.value?.assets != null ? (currentTenant.value?.assets?.home_logo as string) : DEFAULT_AVATAR,
  )

  const usersArray = computed((): User[] => Object.values(usersById.value))
  const accountsMenuList = computed((): AccountMenuItem[] => {
    const currentDomainName = currentHostname()
    const menuItems: AccountMenuItem[] = []

    // Don’t show tenant accounts in padlet.com dashboard
    // And don’t show non-tenant accounts in padlet.org dashboard

    usersArray.value
      .filter((user) => user.tenant_id === currentTenant.value?.id && user.visible)
      .forEach((user) => {
        const key: AccountKey = { type: 'user', id: user.id }
        const tenantId: TenantId = user.tenant_id
        const tenant: Tenant = tenantsById.value[tenantId]
        const tenantDomainName: string = tenant?.domain_name ?? NATIVE_HOST
        const isSameDomain = currentDomainName === tenantDomainName
        const isNative = tenantId === NATIVE_TENANT_ID
        const isLibrary = false
        const libraryId = null
        const displayName: string = isNative ? displayNameForUser(user) : tenant?.name ?? ''
        const url = `https://${tenantDomainName}/dashboard`
        const username: string = user.username ?? ''
        const wallsUsed: number = user.quota.walls_used
        const wallsLimit: number = user.quota.walls_limit
        const remainingWallsQuota: number = user.quota.walls_limit - user.quota.walls_used
        const canMakeWall = true
        const canArchive = user.is_archive_enabled ?? false
        const membershipTier = user.membership_tier

        // if no uploaded tenant logo, logo is default padlet logo
        let logo = tenant?.assets != null ? (tenant.assets.home_logo as string) : DEFAULT_AVATAR
        if (isNative && user?.avatar !== undefined) logo = user.avatar

        menuItems.push({
          key,
          tenantId,
          displayName,
          logo,
          tenantDomainName,
          isSameDomain,
          isNative,
          isLibrary,
          libraryId,
          url,
          username,
          wallsUsed,
          wallsLimit,
          remainingWallsQuota,
          canMakeWall,
          canArchive,
          membershipTier,
        })
      })
    wallViewableAndVisibleLibrariesArray.value.forEach((library) => {
      const key: AccountKey = { type: 'library', id: library.id }
      const tenantId: TenantId = NATIVE_TENANT_ID
      const tenantDomainName = library.host ?? NATIVE_HOST
      const isSameDomain = currentDomainName === tenantDomainName
      const isNative = true
      const isLibrary = true
      const displayName: string = library.name
      const logo = library.avatar as string
      const fallbackLogo = `https://padlet.net/homes/${library.id}.png`
      const libraryId = library.id
      const slug = library.slug
      const url = library.dashboardUrl
      const canViewGallery = library.libraryAccess?.canViewGallery
      const wallsUsed: number = library.quota.wallsUsed
      const wallsLimit: number = library.quota.wallsLimit
      const remainingWallsQuota = library.quota.wallsLimit - library.quota.wallsUsed
      const canMakeWall = wallCreatableLibraryIds.value.includes(library.id)
      const canArchive = library.isArchiveEnabled ?? false
      const membershipTier = library.membershipTier

      menuItems.push({
        key,
        tenantId,
        displayName,
        logo,
        fallbackLogo,
        tenantDomainName,
        isSameDomain,
        isNative,
        isLibrary,
        libraryId,
        url,
        slug,
        canViewGallery,
        wallsUsed,
        wallsLimit,
        remainingWallsQuota,
        canMakeWall,
        canArchive,
        membershipTier,
      })
    })
    return sortBy(menuItems, ['tenantId'])
  })

  const defaultPersonalLibrary = computed((): Partial<Library> => {
    return {
      name: displayNameForUser(user.value),
      avatar: user.value.avatar,
      libraryType: LibraryType.Solo,
      libraryAccess: EMPTY_LIBRARY_ACCESS,
      slug: user.value.username,
    }
  })

  const initialized = ref(false)
  async function initialize(params?: { user: User }): Promise<void> {
    user.value = params?.user ?? initialUser

    await useUserAccountsStore().fetchAccounts()
    await useUserAccountsStore().fetchWallViewableLibraries()
    await useUserAccountsStore().fetchWallCreatableLibraries()

    initialized.value = true
  }

  function refreshWallQuotas(payload?: { forceQuotaRefresh: boolean }): void {
    void fetchAccounts({ forceQuotaRefresh: payload?.forceQuotaRefresh })
  }

  const fetchAccountsStatus = ref<FetchJsonStatus>(FetchJsonStatus.Fetching)
  const isAccountsFetched = computed((): boolean => fetchAccountsStatus.value === FetchJsonStatus.Completed)
  const isFetchingAccounts = computed((): boolean => fetchAccountsStatus.value === FetchJsonStatus.Fetching)

  async function fetchAccounts(params?: { forceQuotaRefresh: boolean | undefined }): Promise<void> {
    const forceRefreshQuota = params?.forceQuotaRefresh === true ? 'true' : 'false'
    const urlParams = new URLSearchParams({
      forceQuotaRefresh: forceRefreshQuota,
    })
    try {
      await fetchCachedQuery<JsonApiResponse<User | Library, Tenant>>({
        cacheKey: ['fetchAccounts', buildUserIdCacheKey(currentUser.value.id)],
        queryFn: async () => await fetchJson(`/api/1/accounts?${urlParams.toString()}`),
        onFetchSuccess: (response) => {
          const accountData = response.data as Array<JsonApiData<User | Library>>
          const tenantData = response.included as Array<JsonApiData<Tenant>>

          tenantData.forEach((item) => {
            if (item.type === 'tenant') {
              tenantsById.value = { ...tenantsById.value, [item.id]: item.attributes }
            }
          })

          accountData.forEach((account) => {
            if (account.type === 'user') {
              usersById.value = { ...usersById.value, [account.id]: account.attributes }
            }
            if (account.type === 'library') {
              librariesById.value = { ...librariesById.value, [account.id]: account.attributes }
            }
          })
          fetchAccountsStatus.value = FetchJsonStatus.Completed
        },
      })
    } catch (error) {
      genericFetchError({ error, source: 'UserAccountsStoreFetchAccounts' })
      fetchAccountsStatus.value = FetchJsonStatus.Error
    }
  }

  /**
   * ==================== LIBRARY ACCOUNTS ====================
   */

  const librariesById = ref<Record<LibraryId, Library>>({})
  const wallViewableLibraries = ref<Library[]>([])
  const wallViewableLibraryIds = computed<LibraryId[]>(() => wallViewableLibraries.value.map((library) => library.id))
  const wallCreateableLibraries = ref<Library[]>([])
  const wallCreatableLibraryIds = computed<LibraryId[]>(() =>
    wallCreateableLibraries.value.map((library) => library.id),
  )

  const wallViewableAndVisibleLibrariesArray = computed((): Library[] =>
    librariesArray.value.filter((library) => library.visible),
  )
  const librariesArray = computed((): Library[] => Object.values(librariesById.value))

  async function fetchWallViewableLibraries(): Promise<void> {
    try {
      await fetchCachedQuery<JsonApiResponse<Library>>({
        cacheKey: ['fetchWallViewableLibraries', buildUserIdCacheKey(currentUser.value.id)],
        queryFn: async () => await LibraryApi.fetchViewableLibraries({ userId: currentUser.value.id }),
        onFetchSuccess: (response) => {
          const nodes = response.data as Array<JsonAPIResource<Library>>
          const libraries = nodes.map((record) => record.attributes)
          libraries.forEach((library) => (librariesById.value = { ...librariesById.value, [library.id]: library }))
          wallViewableLibraries.value = libraries
        },
      })
    } catch (error) {
      genericFetchError({ error, source: 'UserAccountsStoreFetchWallViewableLibraries' })
    }
  }

  const fetchWallCreatableLibrariesStatus = ref<FetchJsonStatus>(FetchJsonStatus.Fetching)
  const isFetchingWallCreatableLibraries = computed(
    () => fetchWallCreatableLibrariesStatus.value === FetchJsonStatus.Fetching,
  )

  async function fetchWallCreatableLibraries(): Promise<void> {
    try {
      await fetchCachedQuery<JsonApiResponse<Library>>({
        cacheKey: ['fetchWallCreatableLibraries', buildUserIdCacheKey(currentUser.value.id)],
        queryFn: async () => await LibraryApi.fetchCreatableAndVisibleLibraries({ userId: currentUser.value.id }),
        onFetchSuccess: (response) => {
          const nodes = response.data as Array<JsonAPIResource<Library>>
          const libraries = nodes.map((record) => record.attributes)
          wallCreateableLibraries.value = libraries
          fetchWallCreatableLibrariesStatus.value = FetchJsonStatus.Completed
        },
      })
    } catch (error) {
      genericFetchError({ error, source: 'UserAccountsStoreFetchWallCreatableLibraries' })
    }
  }

  const isUserLibraryMembershipFetched = ref(false)
  const userLibraryMemberships = ref<LibraryMembership[]>([])
  const userRolesByLibraryId = computed(() => {
    return userLibraryMemberships.value.reduce((accumulator, membership) => {
      accumulator[membership.libraryId] = membership.role
      return accumulator
    }, {})
  })

  async function fetchUserLibraryMemberships(): Promise<void> {
    try {
      await fetchCachedQuery<JsonApiResponse<LibraryMembership>>({
        cacheKey: ['fetchUserLibraryMemberships', buildUserIdCacheKey(currentUser.value.id)],
        queryFn: async () => await fetchJson(`/api/1/users/${currentUser.value.id}/libraries/memberships`),
        onFetchSuccess: (response) => {
          const libraryMemberships = (response?.data as Array<JsonApiData<LibraryMembership>>).map(
            (membership) => membership.attributes,
          )
          userLibraryMemberships.value = libraryMemberships
        },
      })
      isUserLibraryMembershipFetched.value = true
    } catch (error) {
      genericFetchError({ error, source: 'UserAccountsStoreFetchUserLibraryMemberships' })
    }
  }

  // Checks for account type, tenant and library teacher and student roles.
  const hasTeacherLevelAccess = computed((): boolean => {
    return (
      Object.values(userRolesByLibraryId.value).some(
        (role) => role === LibraryMembershipRole.Teacher || role === LibraryMembershipRole.Admin,
      ) ||
      Object.values(usersById.value).some(
        (user) =>
          user.role === UserRole.TEACHER ||
          user.role === UserRole.ADMIN ||
          user.account_type === UserAccountType.TEACHER,
      )
    )
  })

  const hasStudentLevelAccess = computed((): boolean => {
    return (
      Object.values(userRolesByLibraryId.value).some((role) => role === LibraryMembershipRole.Student) ||
      Object.values(usersById.value).some(
        (user) => user.role === UserRole.STUDENT || user.account_type === UserAccountType.STUDENT,
      )
    )
  })

  return {
    /**
     * ==================== PERSONAL ACCOUNTS ====================
     */

    user,
    usersById,
    tenantsById,
    currentUser,
    currentTenant,
    currentTenantName,
    currentTenantLogo,
    usersArray,
    accountsMenuList,
    isFetchingAccounts,
    isNativeAccount,
    defaultPersonalLibrary,
    initialize,
    refreshWallQuotas,
    fetchAccounts,
    isAccountsFetched,

    /**
     * ==================== LIBRARY ACCOUNTS ====================
     */

    librariesById,
    wallViewableLibraryIds,
    wallCreatableLibraryIds,
    isFetchingWallCreatableLibraries,
    wallViewableAndVisibleLibrariesArray,
    wallCreateableLibraries,
    wallViewableLibraries,
    librariesArray,
    userLibraryMemberships,
    userRolesByLibraryId,
    isUserLibraryMembershipFetched,
    initialized,
    fetchWallViewableLibraries,
    fetchWallCreatableLibraries,
    fetchUserLibraryMemberships,
    hasTeacherLevelAccess,
    hasStudentLevelAccess,
  }
})
